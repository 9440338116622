<script setup lang="ts">
const props = defineProps<{
    spaceKey: string,
}>()
import bus from '../lib/bus';
import { onMounted, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { getSpaceKeyById } from '../lib/helper/useWap';
import { Haa } from '../lib/index';
const { adId, spaceInfo, handleAdClose, handleAdError, handleAdClick } = Haa.util().useWapHook(props.spaceKey);
const initAdScript = () => {
    if (spaceInfo.data) {
        let wap = document.getElementById('wap-native-' + adId);
        let script = document.createElement('script');
        script.src = spaceInfo.data.space_js;
        wap?.appendChild(script);
    }
}
watch(
    () => spaceInfo.data,
    () => {
        initAdScript();
    }
)
onMounted(() => {
    initAdScript();
})

const emitAdStage = defineEmits(['adclose', 'aderror', 'adclick']);
const handleClose = (params) => {
    let spaceKey = getSpaceKeyById(params.spaceId);
    if (spaceKey == props.spaceKey) {
        emitAdStage('adclose');
    }
    handleAdClose(params);
}
const handleError = (params) => {
    let spaceKey = getSpaceKeyById(params.spaceId);
    if (spaceKey == props.spaceKey) {
        emitAdStage('aderror');
    }
    handleAdError(params);
}
const handleClick = (params) => {
    let spaceKey = getSpaceKeyById(params.spaceId);
    if (spaceKey == props.spaceKey) {
        emitAdStage('adclick');
    }
    handleAdClick(params);
}
onBeforeMount(() => {
    bus.on('adclose', handleClose);
    bus.on('aderror', handleError);
    bus.on('adclick', handleClick);
});
onBeforeUnmount(() => {
    bus.off('adclose', handleClose);
    bus.off('aderror', handleError);
    bus.off('adclick', handleClick);
})
</script>

<template>
    <div :id="`wap-native-${adId}`" class="wap-native-wrapper">
    </div>
</template>

<style scoped lang="less">

</style>
